import React from 'react'
import './bodyContent.css';




const content = [{
  Title:'Contenido 1 - Titulo' ,
  Content:'Content 1 - Body Content',
  Footer:'Content 1 - Footer'
}]
function bodyContent() {
  return (
    <div>
      <div className='BCPALL'> {content.map((item)=>(      <div key={item.id}> {/* Es importante agregar una key única */}
      <div className='headerBCP'> <p>{item.Title}</p>   </div>
      <div className='bodyBCP'> <p>{item.Content}</p>   </div>
      <div className='footerBCP'> <p>{item.Footer}</p>   </div>
    </div>))}
      </div>
    </div>
  )
}

export default bodyContent
