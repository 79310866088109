import React from 'react'
import  './dbodyOrders.css';

const content2 = [{
  Title:'Order 1 - Titulo' ,
  Content:'Order 1 - Body Orders',
  Footer:'Order 1 - Footer'
}]

function dbodyOrders() {
  return (
    <div>
    <div className='BOPALL'> {content2.map((item)=>(      <div key={item.id}> {/* Es importante agregar una key única */}
    <div className='headerBOP'> <p>{item.Title}</p>   </div>
    <div className='bodyBOP'> <p>{item.Content}</p>   </div>
    <div className='footerBOP'> <p>{item.Footer}</p>   </div>
  </div>))}
    </div>
  </div>
)
}

export default dbodyOrders
