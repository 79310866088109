import React from 'react'
import './dreviews.css';

const content4 = [{
  Title:'Reviews 1 - Titulo' ,
  Content:'Order 1 - Body Settings',
  Footer:'Order 1 - Footer'
}]





function Dreviews() {
  return (
<div>
    <div className='BSPALL'> {content4.map((item)=>(      <div key={item.id}> {/* Es importante agregar una key única para React por que es hueviado */}
    <div className='headerBSP'> <p>{item.Title}</p>   </div>
    <div className='bodyBSP'> <p>{item.Content}</p>   </div>
    <div className='footerBSP'> <p>{item.Footer}</p>   </div>
  </div>))}
    </div>
  </div>
)
}


export default Dreviews
