import React from 'react'

//Nav de la pagina
import Nav from '../navBar/navBar.jsx'
//Contenido
import Header from './Header/Header.jsx';
import BodyContent1 from './BodyContent/bodyContent.jsx';
import DbodyOrders from './BodyContent/dbodyOrders.jsx';
import Dcustomers from './BodyContent/dcustomers.jsx';
import Dreviews from './BodyContent/dreviews.jsx';

//CSS
import './dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function dashboard() {
  return (
    <div className='DivMayor'>
      <div className=' grid-container'>
        <div className='NavBar'>
        <Nav/>
        <div className='Body_Dashboard'>
          <div className='Body_Header1'>
            <Header />
          </div>
          <div className='Boby_BodyMerged'>
          <div className="d-flex flex-wrap" style={{ height: '10vh' }}>
            <div className="col-6 p-1" style={{ backgroundColor: '#transparent' }}>
              {/* Div 1 Total Content */}
              <BodyContent1/>
            </div>
            <div className="col-6 p-1" style={{ backgroundColor: '#transparent' }}>
              {/* Div2  Orders */}
              <DbodyOrders/>
            </div>
            <div className="col-6 p-1" style={{ backgroundColor: '#transparent' }}>
              {/* Div3 = Customers */}
              <Dcustomers />
            </div>
            <div className="col-6 p-1" style={{ backgroundColor: '#transparent' }}>
            {/* Div4 = Reviews */}
            <Dreviews />
            </div>
          </div>
          </div>
          <div className='Body_Footer'>
            {/* Espacio para mostrar el listado de productos con mayor pedidos */}
            <div className='BestSellingProducts '>






            </div>
 
 
 
 
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default dashboard
