import React from 'react'
import './dcustomers.css';


const content3 = [{
  Title:'Customers 1 - Titulo' ,
  Content:'Order 1 - Body Customers',
  Footer:'Order 1 - Footer'
}]







function dcustomers() {
  return (
<div>
    <div className='BCUSPALL'> {content3.map((item)=>(      <div key={item.id}> {/* Es importante agregar una key única para React por que es hueviado */}
    <div className='headerBCUSP'> <p>{item.Title}</p>   </div>
    <div className='bodyBCUSP'> <p>{item.Content}</p>   </div>
    <div className='footerBCUSP'> <p>{item.Footer}</p>   </div>
  </div>))}
    </div>
  </div>
)
}


export default dcustomers
