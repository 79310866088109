import React from 'react'
import "./Header.css";
import {BiSearch, BiNotification} from 'react-icons/bi';
import { FaRegUserCircle } from "react-icons/fa";

import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
  return (
    <div>
      <div class="d-flex align-items-center BigHeader ">
      <div class="Header1 m-auto search-box">
        <input type="text" class="form-control" placeholder="Search"/>
        <BiSearch className='icon' />
      </div>
      <div class="Header2  notify">
      <BiNotification className='icon'/>
      </div>
      <div class="Header3  profile">
      <FaRegUserCircle className='icon'/>
      </div>
      </div>
    </div>
  )
}

export default Header
