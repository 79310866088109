//Importe de 
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
//Importe de css
import './App.css';
//Paginas de acceso publico
import Login from './components/login/login.jsx';
import Register from './components/register/register.jsx';
import NotFound  from './components/notfound/notfound.jsx';
//Componente NavBar
import NavBar from './components/navBar/navBar.jsx'
//Componentes generales de la Pagina
import Dashboard from './components/dashboard/dashboardApp.jsx';
import Customers from './components/customers/customers.jsx';
import Orders from './components/orders/orders.jsx';
import Report from './components/reeport/report.jsx';
import Settings from './components/settings/settings.jsx';
//Componentes del usuario
import Config_usr from './components/configuraciones_usr/config_usr.jsx';
import Perfil from './components/perfil/perfil.jsx';
import Logout from './components/logout/logout.jsx';





function App() {
  return (
    <div className="App">
      <Router>
                  <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path="/NavBar" element={<NavBar />} />
                    <Route exact path="/Dashboard" element={<Dashboard />} />
                    <Route exact path="/Customers" element={<Customers />} />
                    <Route exact path="/Orders" element={<Orders />} />
                    <Route exact path="/Report" element={<Report />} />
                    <Route exact path="/Settings" element={<Settings />} />
                    <Route exact path="/Config_usr" element={<Config_usr />} />
                    <Route exact path="/Perfil" element={<Perfil />} />
                    <Route exact path="/Logout" element={<Logout />} />
                    <Route path="*"element={<NotFound />} />
                  </Routes>
      </Router>
    </div>
  );
}

export default App;
